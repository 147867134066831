import americana from './americana';
import campinaGrande from './campinaGrande';
import campinas from './campinas';
import joaoPessoa from './joaoPessoa';
import portoAlegre from './portoAlegre';
import recife from './recife';

export default [
  americana,
  campinaGrande,
  campinas,
  joaoPessoa,
  portoAlegre,
  recife,
];
