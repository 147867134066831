export const supporters = [
  {
    nome: 'Politize!',
    logo: require('../assets/img/supporters/politize.png'),
    link: 'https://www.politize.com.br/',
  },
  {
    nome: 'Casa Hacker!',
    logo: require('../assets/img/supporters/casahacker.png'),
    link: 'https://casahacker.org/',
  },
];
