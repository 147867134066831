export const genders = [
  { category: 'Não binário' },
  { category: 'Feminino' },
  { category: 'Masculino' },
  { category: 'Prefiro não declarar' },
];

export const socialGroups = [
  { letter: 'L', name: 'Lésbica' },
  { letter: 'G', name: 'Gay' },
  { letter: 'B', name: 'Bissexual' },
  { letter: 'T', name: 'Transgêneros, Transsexuais ou Travestis' },
  { letter: 'Q', name: 'Queer' },
  { letter: 'I', name: 'Intersexo' },
  { letter: 'A', name: 'Assexual' },
  { letter: 'P', name: 'Panssexual' },
  { letter: '+', name: '+' },
];

export const politicalParties = [
  {
    nome: 'Avante',
    sigla: 'AVANTE',
    numero: 70,
  },
  {
    nome: 'Cidadania',
    sigla: 'CIDADANIA',
    numero: 23,
  },
  { nome: 'Democracia Cristã', sigla: 'DC', numero: 27 },
  { nome: 'Democratas', sigla: 'DEM', numero: 25 },
  { nome: 'Movimento Democrático Brasileiro', sigla: 'MDB', numero: 15 },
  { nome: 'Partido Novo', sigla: 'NOVO', numero: 30 },
  { nome: 'Partido Patriota ', sigla: 'Patriota ', numero: 51 },
  { nome: 'Partido Comunista do Brasil', sigla: 'PC do B', numero: 65 },
  { nome: 'Partido Comunista Brasileiro', sigla: 'PCB', numero: 21 },
  { nome: 'Partido da Causa Operária', sigla: 'PCO', numero: 29 },
  { nome: 'Partido Democrático Trabalhista', sigla: 'PDT', numero: 12 },
  { nome: 'Partido Liberal', sigla: 'PL', numero: 22 },
  { nome: 'Partido da Mulher Brasileira', sigla: 'PMB', numero: 35 },
  { nome: 'Partido da Mobilização Nacional', sigla: 'PMN', numero: 33 },
  { nome: 'Podemos', sigla: 'PODE', numero: 19 },
  { nome: 'Partido Progressistas', sigla: 'PP', numero: 11 },
  { nome: 'Partido Republicano da Ordem Social', sigla: 'PROS', numero: 90 },
  {
    nome: 'Partido Renovador Trabalhista Brasileiro',
    sigla: 'PRTB',
    numero: 28,
  },
  { nome: 'Partido Socialista Brasileiro', sigla: 'PSB', numero: 40 },
  { nome: 'Partido Social Cristão', sigla: 'PSC', numero: 20 },
  { nome: 'Partido Social Democrático', sigla: 'PSD', numero: 55 },
  { nome: 'Partido Social Democracia Brasileira', sigla: 'PSDB', numero: 45 },
  { nome: 'Partido Social Liberal', sigla: 'PSL', numero: 17 },
  { nome: 'Partido Socialismo e Liberdade', sigla: 'PSOL', numero: 50 },
  {
    nome: 'Partido Socialista dos Trabalhadores Unificado',
    sigla: 'PSTU',
    numero: 16,
  },
  { nome: 'Partido dos Trabalhadores', sigla: 'PT', numero: 13 },
  { nome: 'Partido Trabalhista Brasileiro', sigla: 'PTB', numero: 14 },
  { nome: 'Partido Trabalhista Cristão', sigla: 'PTC', numero: 36 },
  { nome: 'Partido Verde', sigla: 'PV', numero: 43 },
  { nome: 'Rede Sustentabilidade', sigla: 'REDE', numero: 18 },
  { nome: 'Republicanos', sigla: 'REPUBLICANOS', numero: 10 },
  { nome: 'Solidariedade ', sigla: 'SD', numero: 77 },
  { nome: 'Unidade Popular', sigla: 'UP', numero: 80 },
];

export const ages = [
  { category: '18-24', description: '18 a 24 anos' },
  { category: '25-29', description: '25 a 29 anos' },
  { category: '30-34', description: '30 a 34 anos' },
  { category: '35-39', description: '35 a 39 anos' },
  { category: '40-44', description: '40 a 44 anos' },
  { category: '45-49', description: '45 a 49 anos' },
  { category: '50-54', description: '50 a 54 anos' },
  { category: '55-59', description: '55 a 59 anos' },
  { category: '60-64', description: '60 a 64 anos' },
  { category: '65-69', description: '65 a 69 anos' },
  { category: '70-74', description: '70 a 74 anos' },
  { category: '75-79', description: '75 a 79 anos' },
  { category: '80-84', description: '80 a 84 anos' },
  { category: '85-89', description: '85 a 89 anos' },
  { category: '90+', description: 'Acima de 90 anos' },
];

export const ethnicGroup = [
  { category: 'Branca' },
  { category: 'Preta' },
  { category: 'Amarela' },
  { category: 'Parda' },
  { category: 'Indígena' },
];
